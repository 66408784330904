import React, {useState} from 'react';
import {
  Button,
  Card,
  Container,
} from 'react-bootstrap';
import moment from 'moment';
import { ReqModal } from '../components/requestModal';


const getDate = (createdAt) => moment.utc(createdAt).format('MMM DD, YYYY');

export const ProjectItem = ({project}) => {
  const [showReqModal, setShowReqModal] = useState(false);

  return (
                  <Container>
                    <div>
                        <Card className='shadow-sm mb-5'>
                          <Card.Body>
                            <Card.Title>
                              <div className='d-flex justify-content-between'>
                                <span>{project.title}</span>
                                <span className='text-muted small'>
                                  Submission Deadline :
                              {getDate(project ?.submissionDeadline)}
                                </span>
                              </div>
                            </Card.Title>
                            <Card.Subtitle className='mb-2 mt-2 text-muted text-uppercase'>
                              {project.objective}
                            </Card.Subtitle>
                            <Card.Text>{project.description}</Card.Text>

                            <div className='flex'>
                              <div className='d-inline-block mr-2 border-right border-dark pr-2'>
                                <span>Years of Experience</span>:
                            <span className='font-weight-bold text-muted ml-2 mb-2'>
                                  {project.yearsOfExp}
                                </span>
                              </div>
                              <div className='d-inline-block mr-2 border-right border-dark pr-2'>
                                <span>Average Project Value Experience</span>:
                            <span className='font-weight-bold text-muted ml-2 mb-2'>
                                  {project.avgProjectValueExp}
                                </span>
                              </div>
                              <div className='d-inline-block mr-2'>
                                <span>Acceptance Criteria</span>:
                            <span className='font-weight-bold text-muted ml-2 mb-2'>
                                  {project.acceptanceCriteria}
                                </span>
                              </div>
                            </div>

                            <div className='text-right'>
                              <Button className='btn' variant='dark' onClick={() => setShowReqModal(true)}>
                                Request Project
                          </Button>
                          { showReqModal ? <ReqModal project={project} showReqModal={showReqModal} setShowReqModal={setShowReqModal}/> : null }
                            </div>
                          </Card.Body>
                        </Card>
                    </div>
                  </Container>
  );
};
