import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, Redirect } from 'react-router-dom';

import { useEffect } from 'react';
import { isLoggedIn } from '../features/userAuthentication';
// import Navigationbar from '../components/Navigation/Navbar/Navbar';

const ProtectedRoutes = ({ children }) => {
  const { isAuthenticated, isLoading } = useSelector((state) => state.user);

  const dispatch = useDispatch();

  const history = useHistory();

  const isNotIndex = history.location.pathname !== '/';

  const isIndex = history.location.pathname === '/';
  useEffect(() => {
    if (!isAuthenticated) {
      dispatch(isLoggedIn());
    }
  }, [isAuthenticated, dispatch]);

  if (isLoading) return 'loading';

  return (
    <>
      {isAuthenticated && isNotIndex && !isLoading ? (
        <>
          <div>{children}</div>
        </>
      ) : (
        <>
          {isAuthenticated && isIndex && !isLoading ? (
            <Redirect to='/profile' />
          ) : (
            <Redirect to='/' />
          )}
        </>
      )}
    </>
  );
};
export default ProtectedRoutes;
