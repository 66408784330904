import React, { useState, useEffect } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import Select from 'react-select';
import axios from 'axios';
// import { useDispatch} from 'react-redux';
import { faEdit, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useForm } from 'react-hook-form';
import notifications from '../../components/notifications';
import { usePlaceHolder } from '../../utils/usePlaceholder.js';
import { Loader } from '../../components/spinner';
// import { isLoggedIn } from '../../features/userAuthentication';

export const AgentProfile = (props) => {
    // const dispatch = useDispatch();
    // dispatch(isLoggedIn());
    const { register, handleSubmit, errors } = useForm();
    const [loading, setLoading] = useState(false);
    const [contactLoading, setContactLoading] = useState(false);
    const [spinnerLoading, setSpinnerLoading] = useState(false);
    const [marketOfOperation, setMarketOfOperation] = useState(null);
    const [agentProfileData, setAgentProfileData] = useState(null);
    const [nameModalShow, setNameModalShow] = useState(false);
    const [emailModalShow, setEmailModalShow] = useState(false);
    const [phoneModalShow, setPhoneModalShow] = useState(false);
    const [contactPersonName, setContactPersonName] = useState(null);
    const [contactPersonEmail, setContactPersonEmail] = useState(null);
    const [contactPersonPhoneNumber, setContactPersonPhone] = useState(null);
    const {
        placeholder,
        file,
        inputFile,
        onChange,
        onImageClick,
        resetPlaceholder,
    } = usePlaceHolder();

    // getting agent's profile data if already exists
    useEffect(() => {
        setSpinnerLoading(true);
        axios
            .get('/profile?profileType=agent')
            .then((res) => {
                setAgentProfileData(res.data.profile);
                setSpinnerLoading(false);
                console.log(agentProfileData);
            })
            .catch((err) => {
                setSpinnerLoading(false);
                console.log(err.response.data);
            });
    }, []);

    const mooOptions = [
        { value: 'Road & Transport', label: 'Road & Transport' },
        { value: 'Energy & Power', label: 'Energy & Power' },
        {
            value: 'Port & Coastal Development',
            label: 'Port & Coastal Development',
        },
        {
            value: 'River & Waterways Development',
            label: 'River & Waterways Development',
        },
    ];

    const onSubmit = (data) => {
        console.log(data);
        setLoading(true);
        const {
            orgName,
            description,
            addressLine1,
            addressLine2,
            city,
            postCode,
        } = data;
        const formdata = new FormData();
        if (placeholder) {
            formdata.append('imageUrl', file);
        }
        formdata.append('addressLine1', addressLine1);
        formdata.append('addressLine2', addressLine2);
        formdata.append('city', city);
        formdata.append('postCode', postCode);
        formdata.append('orgName', orgName);
        formdata.append('description', description);
        if (marketOfOperation !== null) {
            formdata.append('marketOfOperation', marketOfOperation);
        }

        axios
            .patch('/profile/agent', formdata)
            .then((res) => {
                setLoading(false);
                console.log(res);
                setAgentProfileData(res.data.doc);
                notifications.success('Success', 'Profile Updated Successfully');
            })
            .catch((err) => {
                setLoading(false);
                notifications.error('Profile Form Error', err.response.data.message);
            });
    };

    const updateContactHandler = (data) => {
        setContactLoading(true);
        axios
            .patch('https://bdinvest.herokuapp.com/profile/agent/contact', data)
            .then((res) => {
                setContactLoading(false);
                setAgentProfileData(res.data.doc);
                setNameModalShow(false);
                setEmailModalShow(false);
                setPhoneModalShow(false);
                console.log(res);
            })
            .catch((err) => {
                setContactLoading(false);
                notifications.error('Contact Form Error', err.response.data.message);
            });
    };

    return (
        <>
            {spinnerLoading ? (
                <Loader />
            ) : (
                    <>
                        <Form onSubmit={handleSubmit(onSubmit)}>
                            {/* // picture */}
                            <div className='my-4 d-flex flex-column align-items-center'>
                                <Form.Label>Change Avatar</Form.Label>
                                <div
                                    className={`${
                                        placeholder ? '' : `bg-grey`
                                        } upload-circle shadow-sm rounded-circle`}
                                    type='button'
                                    onClick={onImageClick}
                                >
                                    <input
                                        type='file'
                                        id='file'
                                        accept='image/png/jpeg'
                                        ref={inputFile}
                                        style={{ display: 'none' }}
                                        onChange={onChange}
                                        name='file'
                                    />
                                    {placeholder ? (
                                        <span class='avatar-container'>
                                            <img
                                                src={placeholder}
                                                height='100%'
                                                width='100%'
                                                alt=''
                                                className='h-100 w-100 rounded-circle'
                                            />
                                        </span>
                                    ) : (
                                            <span class='avatar-container'>
                                                <img
                                                    height='100%'
                                                    width='100%'
                                                    src={
                                                        agentProfileData !== null
                                                            ? agentProfileData.imageUrl
                                                            : 'https://graph.facebook.com/100008343750912/picture?width=400&height=400'
                                                    }
                                                />
                                            </span>
                                        )}
                                </div>
                            </div>
                            {/* // section for agent profile */}
                            <Row>
                                <Col md={6}>
                                    <Form.Group>
                                        <Form.Label>Organization Name</Form.Label>
                                        <Form.Control
                                            type='text'
                                            name='orgName'
                                            defaultValue={agentProfileData !== null
                                                ? agentProfileData.orgName
                                                : ''}
                                            placeholder={
                                                agentProfileData !== null
                                                    ? ''
                                                    : 'Enter Org name'
                                            }
                                            ref={register({ required: true })}
                                        />
                                        {errors.orgName && (
                                            <small className='font-weight-bold text-danger'>
                                                This field is required
                    </small>
                                        )}
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group
                                        className='small mt-1'
                                        controlId='formBasicPassword'
                                    >
                                        <Form.Label>Market of operation:</Form.Label>
                                        <Select
                                            options={mooOptions}
                                            placeholder={
                                                agentProfileData !== null
                                                    ? agentProfileData.marketOfOperation
                                                    : 'Area of operation'
                                            }
                                            onChange={(e) => setMarketOfOperation(e.value)}
                                        />
                                    </Form.Group>
                                </Col>
                                {/* <Col className='align-self-end'>
                  <Button block variant='dark' className='mb-3'>
                    Save
                  </Button>
                </Col> */}
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <Form.Group>
                                        <Form.Label>Description Of Organization</Form.Label>
                                        <Form.Control
                                            as='textarea'
                                            rows={4}
                                            name='description'
                                            ref={register({ required: true })}
                                            defaultValue={agentProfileData !== null
                                                ? agentProfileData.description
                                                : ''}
                                            placeholder={
                                                agentProfileData !== null
                                                    ? ''
                                                    : 'Enter description of organization'
                                            }
                                        />
                                        {errors.description && (
                                            <small className='font-weight-bold text-danger'>
                                                This field is required
                    </small>
                                        )}
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row>
                                <Col md={12}>
                                    <Form.Group>
                                        <Form.Label>Address Line1</Form.Label>
                                        <Form.Control
                                            type='text'
                                            defaultValue={agentProfileData !== null
                                                ? agentProfileData.addressLine1
                                                : ''}
                                            placeholder={
                                                agentProfileData !== null
                                                    ? ''
                                                    : 'addressLine1'
                                            }
                                            name='addressLine1'
                                            ref={register({ required: true })}
                                        />
                                        {errors.addressLine1 && (
                                            <small className='font-weight-bold text-danger'>
                                                This field is required
                    </small>
                                        )}
                                    </Form.Group>
                                </Col>
                                <Col md={12}>
                                    <Form.Group>
                                        <Form.Label>Address Line2</Form.Label>
                                        <Form.Control
                                            type='text'
                                            defaultValue={agentProfileData !== null
                                                ? agentProfileData.addressLine2
                                                : ''}
                                            placeholder={
                                                agentProfileData !== null
                                                    ? ''
                                                    : 'Address Line2'
                                            }
                                            name='addressLine2'
                                            ref={register({ required: true })}
                                        />
                                        {errors.addressLine2 && (
                                            <small className='font-weight-bold text-danger'>
                                                This field is required
                    </small>
                                        )}
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group>
                                        <Form.Label>City</Form.Label>
                                        <Form.Control
                                            type='text'
                                            defaultValue={agentProfileData !== null ? agentProfileData.city : ''}
                                            placeholder={
                                                agentProfileData !== null ? '' : 'city'
                                            }
                                            name='city'
                                            ref={register({ required: true })}
                                        />
                                        {errors.city && (
                                            <small className='font-weight-bold text-danger'>
                                                This field is required
                    </small>
                                        )}
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group>
                                        <Form.Label>PostCode</Form.Label>
                                        <Form.Control
                                            type='number'
                                            defaultValue={agentProfileData !== null
                                                ? agentProfileData.postCode
                                                : ''}
                                            placeholder={
                                                agentProfileData !== null
                                                    ? ''
                                                    : 'postCode'
                                            }
                                            name='postCode'
                                            ref={register({ required: true })}
                                        />
                                        {errors.postCode && (
                                            <small className='font-weight-bold text-danger'>
                                                This field is required
                    </small>
                                        )}
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row>
                                <Col xs={12}>
                                    {' '}
                                    <Form.Label>Contact Person</Form.Label>
                                </Col>
                                <Col md={4}>
                                    <Form.Group>
                                        <Button
                                            variant='bt-box-1'
                                            block
                                            className='p-3 text-left rounded-lg'
                                        >
                                            <div className='small font-weight-bold'>Name</div>
                                            <div className='text-black-50'>
                                                {agentProfileData !== null
                                                    ? agentProfileData.contactPersonName
                                                    : ''}
                                            </div>
                                            <div className='bt-box-1__item '>
                                                <Button
                                                    variant='success'
                                                    className='p-0 rounded-circle text-white d-flex align-items-center justify-content-center mr-1'
                                                    style={{ height: 22, width: 22 }}
                                                    onClick={() => {
                                                        setContactPersonName(
                                                            agentProfileData !== null
                                                                ? agentProfileData.contactPersonName
                                                                : ''
                                                        );
                                                        setNameModalShow(true);
                                                    }}
                                                >
                                                    <FontAwesomeIcon size='xs' icon={faEdit} />
                                                </Button>
                                            </div>
                                        </Button>
                                    </Form.Group>
                                </Col>
                                <Col md={4}>
                                    <Form.Group>
                                        <Button
                                            variant='bt-box-1'
                                            block
                                            className='p-3 text-left rounded-lg'
                                        >
                                            <div className='small font-weight-bold'>Email Address</div>
                                            <div className='text-black-50'>
                                                {agentProfileData !== null
                                                    ? agentProfileData.contactPersonEmail
                                                    : ''}
                                            </div>
                                            <div className='bt-box-1__item '>
                                                <Button
                                                    variant='success'
                                                    className='p-0 rounded-circle text-white d-flex align-items-center justify-content-center mr-1'
                                                    style={{ height: 22, width: 22 }}
                                                    onClick={() => {
                                                        setContactPersonEmail(
                                                            agentProfileData !== null
                                                                ? agentProfileData.contactPersonEmail
                                                                : ''
                                                        );
                                                        setEmailModalShow(true);
                                                    }}
                                                >
                                                    <FontAwesomeIcon size='xs' icon={faEdit} />
                                                </Button>
                                            </div>
                                        </Button>
                                    </Form.Group>
                                </Col>
                                <Col md={4}>
                                    <Form.Group>
                                        <Button
                                            variant='bt-box-1'
                                            block
                                            className='p-3 text-left rounded-lg'
                                        >
                                            <div className='small font-weight-bold'>Phone Number</div>
                                            <div className='text-black-50'>
                                                {agentProfileData !== null
                                                    ? agentProfileData.contactPersonPhoneNumber
                                                    : ''}
                                            </div>
                                            <div className='bt-box-1__item '>
                                                <Button
                                                    variant='success'
                                                    className='p-0 rounded-circle text-white d-flex align-items-center justify-content-center mr-1'
                                                    style={{ height: 22, width: 22 }}
                                                    onClick={() => {
                                                        setContactPersonPhone(
                                                            agentProfileData !== null
                                                                ? agentProfileData.contactPersonPhoneNumber
                                                                : ''
                                                        );
                                                        setPhoneModalShow(true);
                                                    }}
                                                >
                                                    <FontAwesomeIcon size='xs' icon={faEdit} />
                                                </Button>
                                                {/* <Button
                            variant='danger'
                            className='p-0 rounded-circle text-white d-flex align-items-center justify-content-center'
                            style={{ height: 22, width: 22 }}
                          >
                            <FontAwesomeIcon size='xs' icon={faTimes} />
                          </Button> */}
                                            </div>
                                        </Button>
                                    </Form.Group>
                                </Col>
                                <div className='col col-md-6 mt-4 mx-auto px-0'>
                                    <Button
                                        block
                                        variant='dark'
                                        type='submit'
                                        disabled={loading}
                                        className={`${loading === true ? 'button-disabled' : null}`}
                                    >
                                        {loading === true ? (
                                            <div
                                                class='spinner-border spinner-border-sm text-light mr-2 mb-1'
                                                role='status'
                                            >
                                                <span class='sr-only'>Loading...</span>
                                            </div>
                                        ) : null}
                                        Submit
                </Button>
                                </div>
                            </Row>
                        </Form>
                        <Modal
                            size='sm'
                            centered
                            show={nameModalShow}
                            onHide={() => setNameModalShow(false)}
                        >
                            <Modal.Body className='p-4'>
                                <h6 className='mb-3 font-weight-bold'>Update Name</h6>
                                <Form>
                                    <Form.Group controlId='formBasicName'>
                                        <Form.Control
                                            className='input-group-text text-left'
                                            type='text'
                                            value={contactPersonName}
                                            onChange={(e) => setContactPersonName(e.target.value)}
                                            placeholder='Enter your Name'
                                            required={true}
                                        />
                                    </Form.Group>
                                    <div className='mt-4'>
                                        <Button
                                            variant='dark'
                                            block
                                            type='submit'
                                            className='text-capitalize'
                                            onClick={() => updateContactHandler({ contactPersonName })}
                                            disabled={contactLoading}
                                        >
                                            <small>Save Name</small>
                                        </Button>
                                    </div>
                                </Form>
                            </Modal.Body>
                        </Modal>
                        <Modal
                            size='sm'
                            centered
                            show={phoneModalShow}
                            onHide={() => setPhoneModalShow(false)}
                        >
                            <Modal.Body className='p-4'>
                                <h6 className='mb-3 font-weight-bold'>Update Phone</h6>
                                <Form>
                                    <Form.Group controlId='formBasicPhone'>
                                        <Form.Control
                                            className='input-group-text text-left'
                                            type='number'
                                            value={contactPersonPhoneNumber}
                                            onChange={(e) => setContactPersonPhone(e.target.value)}
                                            placeholder='Enter Phone Number'
                                            required
                                        />
                                    </Form.Group>
                                    <div className='mt-4'>
                                        <Button
                                            variant='dark'
                                            block
                                            type='submit'
                                            className='text-capitalize'
                                            onClick={() =>
                                                updateContactHandler({ contactPersonPhoneNumber })
                                            }
                                            disabled={contactLoading}
                                        >
                                            Save
                  </Button>
                                    </div>
                                </Form>
                            </Modal.Body>
                        </Modal>
                        <Modal
                            size='sm'
                            centered
                            show={emailModalShow}
                            onHide={() => setEmailModalShow(false)}
                        >
                            <Modal.Body className='p-4'>
                                <h6 className='mb-3 font-weight-bold'>Update Email</h6>
                                <Form>
                                    <Form.Group controlId='formBasicEmail'>
                                        <Form.Control
                                            className='input-group-text text-left'
                                            type='email'
                                            value={contactPersonEmail}
                                            placeholder='Enter your Email'
                                            onChange={(e) => setContactPersonEmail(e.target.value)}
                                            required
                                        />
                                    </Form.Group>
                                    <div className='mt-4'>
                                        <Button
                                            variant='dark'
                                            block
                                            type='submit'
                                            className='text-capitalize'
                                            onClick={() => updateContactHandler({ contactPersonEmail })}
                                            disabled={contactLoading}
                                        >
                                            <small>Save</small>
                                        </Button>
                                    </div>
                                </Form>
                            </Modal.Body>
                        </Modal>
                    </>
                )}
        </>
    );
};
