import React, { useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import Select from 'react-select';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import notifications from '../../components/notifications';
import { useDispatch } from 'react-redux';
import { logout } from '../../features/userAuthentication';

const averageValuesOptions = [
  { value: '$0 - $100,000', label: '$0 - $100,000' },
  { value: '$100,000 - 250,000', label: '$100,000 - 250,000' },
  { value: '$250,000 - $500,000', label: '$250,000 - $500,000' },
  { value: '$500,000 - $1,000,000', label: '$500,000 - $1,000,000' },
  { value: '$1,000,000 - $5,000,000,', label: '$1,000,000 - $5,000,000,' },
  { value: '$5,000,000 - $10,000,000', label: '$5,000,000 - $10,000,000' },
  { value: '$10,000,000 - $25,000,000', label: '$10,000,000 - $25,000,000' },
  { value: '$25,000,000 - $50,000,000', label: '$25,000,000 - $50,000,000' },
  { value: '$50,000,000 - $100,000,000', label: '$50,000,000 - $100,000,000' },
  {
    value: '$100,000,000 - $250,000,000',
    label: '$100,000,000 - $250,000,000',
  },
  {
    value: '$250,000,000 - $500,000,000',
    label: '$250,000,000 - $500,000,000',
  },
  {
    value: '$500,000,000 - $1,000,000,000',
    label: '$500,000,000 - $1,000,000,000',
  },
  { value: '$1,000,000,000 and ABOVE', label: '$1,000,000,000 and ABOVE' },
];

const yearsOfExpOptions = [
  { value: '1-10', label: '1-10' },
  { value: '10-20', label: '10-20' },
  { value: '20-30', label: '20-30' },
  { value: '30-50', label: '30-50' },
];

const fundingOptions = [
  { value: 'JV (50/50)', label: 'JV (50/50)' },
  { value: 'Equity/Debt', label: 'Equity/Debt' },
  { value: 'Government Funding', label: 'Government Funding' },
  {
    value: 'International Development Partnerfund',
    label: 'International Development Partnerfund',
  },
];

const technicalOptions = [
  { value: 'Design', label: 'Design' },
  { value: 'Build', label: 'Build' },
  { value: 'Engineering', label: 'Engineering' },
  { value: 'Survey', label: 'Survey' },
  { value: 'Analysis', label: 'Analysis' },
  { value: 'Equipment', label: 'Equipment' },
  {
    value: ' Skills & Technical Training',
    label: ' Skills & Technical Training',
  },
];

const feasibilityOptions = [
  { value: 'Technical', label: 'Technical' },
  { value: 'Economic', label: 'Economic' },
  { value: 'Environmental', label: 'Environmental' },
  { value: 'Legal', label: 'Legal' },
  { value: 'Operational', label: 'Operational' },
  { value: 'Scheduling', label: 'Scheduling' },
];

const mediaAndMarketingOptions = [
  { value: 'Video', label: 'Video' },
  { value: 'Drone Footage', label: 'Drone Footage' },
  { value: '3D Scanning', label: '3D Scanning' },
  { value: 'Visualisation', label: 'Visualisation' },
];

const acceptanceCriteriaOptions = [
  { value: 'Finance/funding', label: 'Finance/funding' },
  { value: 'Design', label: 'Design' },
  { value: 'Contracting', label: 'Contracting' },
  { value: 'Feasibility', label: 'Feasibility' },
  {
    value: 'Delivering equipment/labour',
    label: 'Delivering equipment/labour',
  },
  { value: 'Media and Marketing', label: 'Media and Marketing' },
  {
    value: 'Skills and Technical training',
    label: 'Skills and Technical training',
  },
];

export const ProfileDetails = () => {
  const { register, handleSubmit, errors } = useForm();
  const [loading, setLoading] = useState(false);
  const [technical, setTechnical] = useState([]);
  const [financeAndFunding, setFinanceAndFunding] = useState([]);
  const [feasibility, setFeasibility] = useState([]);
  const [mediaAndMarketing, setMediaAndMarketing] = useState([]);
  const [acceptanceCriteria, setAcceptanceCriteria] = useState(null);
  const [avgProjectValueExp, setAvgProjectValueExp] = useState(null);
  const [yearsOfExp, setYearsOfExp] = useState(null);
  const [file, setFile] = useState(null);
  const history = useHistory();
  const dispatch = useDispatch();

  const onSubmit = (data) => {
    let technicalArray = [];
    let feasibilityArray = [];
    let mediaArray = [];
    let fundingArray = [];
    technical.map((el) => technicalArray.push(el.value));
    mediaAndMarketing.map((el) => mediaArray.push(el.value));
    financeAndFunding.map((el) => fundingArray.push(el.value));
    feasibility.map((el) => feasibilityArray.push(el.value));
    setLoading(true);
    const { title, objective, description, submissionDeadline } = data;
    const formdata = new FormData();
    formdata.append('imageUrl', file);
    formdata.append('title', title);
    formdata.append('objective', objective);
    formdata.append('description', description);
    formdata.append('submissionDeadline', submissionDeadline);
    technicalArray.map((val) => formdata.append('technical', val));
    fundingArray.map((val) => formdata.append('financeAndFunding', val));
    feasibilityArray.map((val) => formdata.append('feasibility', val));
    mediaArray.map((val) => formdata.append('mediaAndMarketing', val));
    formdata.append('yearsOfExp', yearsOfExp);
    formdata.append('avgProjectValueExp', avgProjectValueExp);
    formdata.append('acceptanceCriteria', acceptanceCriteria);

    axios
      .post('/project/agent', formdata)
      .then((res) => {
        setLoading(false);
        console.log(res);
        notifications.success('Success', 'Project added Successfully');
        history.push('/profile');
      })
      .catch((err) => {
        setLoading(false);
        notifications.error('Project Form Error', err.response.data.message);
      });
  };

  const handleTechnicalChange = (selectedOptions) =>
    setTechnical(selectedOptions);
  const handleFeasibilityChange = (selectedOptions) =>
    setFeasibility(selectedOptions);
  const handleFundingChange = (selectedOptions) =>
    setFinanceAndFunding(selectedOptions);
  const handleMediaChange = (selectedOptions) =>
    setMediaAndMarketing(selectedOptions);

  return (
    <section className='pt-5 d-flex align-items-center my-5'>
      <Row className='w-100 mx-0 px-lg-5'>
        <Col lg={2} className='mb-4 mb-lg-0'>
          <div className='shadow-sm border p-4 rounded-lg'>
            <ul className='nav flex-column'>
              <li className='nav-item mb-3'>
                <NavLink to='/profile'>Profile</NavLink>
              </li>
              {/* <li className='nav-item mb-3'>
                <NavLink to='/profilePost'>Profile Post</NavLink>
              </li> */}
              <li className='nav-item mb-3'>
                <NavLink to='/profileDetails'>Post Project</NavLink>
              </li>
              <li className='nav-item'>
                <div
                  style={{
                    color: '#ddd',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    dispatch(logout());
                  }}
                >
                  Logout
                </div>
              </li>
            </ul>
          </div>
        </Col>
        <Col>
          <div className='shadow-sm border p-4 rounded-lg'>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <h4>Profile Details</h4>

              <Row>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>Project Title</Form.Label>
                    <Form.Control
                      type='text'
                      placeholder='Project Title'
                      name='title'
                      ref={register({ required: true })}
                    />
                    {errors.title && (
                      <small className='font-weight-bold text-danger'>
                        This field is required
                      </small>
                    )}
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>Project Objective</Form.Label>
                    <Form.Control
                      type='text'
                      name='objective'
                      placeholder='Project Objective'
                      ref={register({ required: true })}
                    />
                    {errors.objective && (
                      <small className='font-weight-bold text-danger'>
                        This field is required
                      </small>
                    )}
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col md={12}>
                  <Form.Group>
                    <Form.Label>Project Description</Form.Label>
                    <Form.Control
                      as='textarea'
                      rows={4}
                      name='description'
                      placeholder='Project Description'
                      ref={register({ required: true })}
                    />
                    {errors.description && (
                      <small className='font-weight-bold text-danger'>
                        This field is required
                      </small>
                    )}
                  </Form.Group>
                </Col>
              </Row>

              <h4 className=' mt-2'>Requirements</h4>

              <Row>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>Finance and funding</Form.Label>
                    <Select
                      isMulti
                      options={fundingOptions}
                      placeholder='Finance and funding'
                      onChange={handleFundingChange}
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>Technical</Form.Label>
                    <Select
                      isMulti
                      options={technicalOptions}
                      placeholder='Technical'
                      onChange={handleTechnicalChange}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>Feasibility</Form.Label>
                    <Select
                      isMulti
                      options={feasibilityOptions}
                      placeholder='Feasibility'
                      onChange={handleFeasibilityChange}
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>Media and marketing</Form.Label>
                    <Select
                      isMulti
                      options={mediaAndMarketingOptions}
                      placeholder='Media and marketing'
                      onChange={handleMediaChange}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <h4 className=' mt-2'>Acceptance Criteria</h4>

              <Row>
                <Col lg={4}>
                  <Form.Group>
                    <Form.Label>Experience area</Form.Label>
                    <Select
                      options={acceptanceCriteriaOptions}
                      placeholder='Experience area'
                      onChange={(e) => setAcceptanceCriteria(e.value)}
                    />
                  </Form.Group>
                </Col>
                <Col lg={4}>
                  <Form.Group>
                    <Form.Label>Experience years</Form.Label>
                    <Select
                      options={yearsOfExpOptions}
                      placeholder='Experience years'
                      onChange={(e) => setYearsOfExp(e.value)}
                    />
                  </Form.Group>
                </Col>

                <Col lg={4}>
                  <Form.Group>
                    <Form.Label>Average project value</Form.Label>
                    <Select
                      options={averageValuesOptions}
                      placeholder='Project Value'
                      onChange={(e) => setAvgProjectValueExp(e.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <h4 className=' mt-2'>Submission deadline</h4>

              <Row>
                <Col md={12}>
                  <Form.Group>
                    <Form.Label>Date picker</Form.Label>
                    <Form.Control
                      type='date'
                      name='submissionDeadline'
                      placeholder='Date picker'
                      ref={register({ required: true })}
                    />
                    {errors.submissionDeadline && (
                      <small className='font-weight-bold text-danger'>
                        This field is required
                      </small>
                    )}
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col md={12}>
                  <Form.Group>
                    {/* <Form.Label>Upload post</Form.Label> */}
                    <Form.File
                      id='custom-file'
                      label='Upload post'
                      required
                      onChange={(e) => setFile(e.target.files[0])}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <div className='col col-md-2 mt-4 ml-auto px-0'>
                <Button
                  block
                  variant='dark'
                  type='submit'
                  disabled={
                    loading ||
                    yearsOfExp === null ||
                    avgProjectValueExp === null ||
                    acceptanceCriteria === null ||
                    feasibility.length === 0 ||
                    technical.length === 0 ||
                    financeAndFunding === 0 ||
                    mediaAndMarketing === 0
                  }
                  className={`${loading === true ? 'button-disabled' : null}`}
                >
                  {loading === true ? (
                    <div
                      class='spinner-border spinner-border-sm text-light mr-2 mb-1'
                      role='status'
                    >
                      <span class='sr-only'>Loading...</span>
                    </div>
                  ) : null}
                  Post
                </Button>
              </div>
            </Form>
          </div>
        </Col>
      </Row>
    </section>
  );
};
