import React from 'react';
import Spinner from 'react-bootstrap/Spinner'

export const Loader = () => {
  return (
    <div className='d-flex h-80vh justify-content-center align-items-center'>
      <Spinner animation="border" role="status">
        <span className="sr-only">Loading...</span>
      </Spinner>
    </div>
  );
};
