import React, { useState, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import axios from 'axios';
import { NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { usePlaceHolder } from '../../utils/usePlaceholder.js';
import { User } from '../../features/userAuthentication';
import { logout, isLoggedIn } from '../../features/userAuthentication';
import { AgentProfile } from './agentProfile';
import { ContractorProfile } from './contractorProfile';

export const Profile = (props) => {
  const [countriesOptions, setCountriesOptions] = useState([]);

  const dispatch = useDispatch();
  const { user } = useSelector(User);
  // dispatch(isLoggedIn());
  console.log(user);
  const {
    placeholder,
    file,
    inputFile,
    onChange,
    onImageClick,
    resetPlaceholder,
  } = usePlaceHolder();

  const employees = [
    { value: '0-50', label: '0-50' },
    { value: '50-100', label: '50-100' },
    { value: '100-500', label: '100-500' },
    { value: '500-1000', label: '500-1000' },
  ];
  const averageValues = [
    { value: '$0 - $100,000', label: '$0 - $100,000' },
    { value: '$100,000 - 250,000', label: '$100,000 - 250,000' },
    { value: '$250,000 - $500,000', label: '$250,000 - $500,000' },
    { value: '$500,000 - $1,000,000', label: '$500,000 - $1,000,000' },
    { value: '$1,000,000 - $5,000,000,', label: '$1,000,000 - $5,000,000,' },
    { value: '$5,000,000 - $10,000,000', label: '$5,000,000 - $10,000,000' },
    { value: '$10,000,000 - $25,000,000', label: '$10,000,000 - $25,000,000' },
    { value: '$25,000,000 - $50,000,000', label: '$25,000,000 - $50,000,000' },
    {
      value: '$50,000,000 - $100,000,000',
      label: '$50,000,000 - $100,000,000',
    },
    {
      value: '$100,000,000 - $250,000,000',
      label: '$100,000,000 - $250,000,000',
    },
    {
      value: '$250,000,000 - $500,000,000',
      label: '$250,000,000 - $500,000,000',
    },
    {
      value: '$500,000,000 - $1,000,000,000',
      label: '$500,000,000 - $1,000,000,000',
    },
    { value: '$1,000,000,000 and ABOVE', label: '$1,000,000,000 and ABOVE' },
  ];

  const yearsOfExp = [
    { value: '1-10', label: '1-10' },
    { value: '10-20', label: '10-20' },
    { value: '20-30', label: '20-30' },
    { value: '30-50', label: '30-50' },
  ];

  useEffect(() => {
    axios
      .get('/countries')
      .then((res) => {
        console.log(res.data);
        setCountriesOptions(res.data.countriesList);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <section className='pt-5 d-flex align-items-center my-5'>
      <Row className=' w-100 mx-0 px-lg-5'>
        <Col lg={2} className='mb-4 mb-lg-0'>
          <div className='shadow-sm border p-4 rounded-lg'>
            <ul className='nav flex-column'>
              <li className='nav-item mb-3'>
                <NavLink to='/profile'>Profile</NavLink>
              </li>
              {user.aoi.value === 'agent' ? (
                <li className='nav-item mb-3'>
                  <NavLink to='/profileDetails'>Post Project</NavLink>
                </li>
              ) : null}
              <li className='nav-item'>
                <div
                  style={{
                    color: '#ddd',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    dispatch(logout());
                  }}
                >
                  Logout
                </div>
              </li>
            </ul>
          </div>
        </Col>
        <Col>
          <div className='shadow-sm border p-4 rounded-lg'>
            <h4 className='text-center'>Your profile</h4>
            {user.aoi.value === 'agent' ? (
              <AgentProfile />
            ) : (
              <ContractorProfile
                countriesOptions={countriesOptions}
                averageValuesList={averageValues}
                employeesList={employees}
                yearsOfExpList={yearsOfExp}
              />
            )}
          </div>
        </Col>
      </Row>
    </section>
  );
};
