import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

// Components
import { Footer } from './components/Footer';
import { NavBar } from './components/NavBar';

// Pages
import { Home } from './pages/Home';
import { MarketPlace } from './pages/MarketPlace';
import { AboutUs } from './pages/AboutUs';
import { ContactUs } from './pages/ContactUs';
import { SignIn } from './pages/Log/SignIn';
import { SignUp } from './pages/Log/SignUp';
import { Profile } from './pages/profile/Profile';
import { ProfileDetails } from './pages/profile/ProfileDetails';
import ProtectedRoutes from './HOC/ProtectedRoutes';

const Routes = () => {
  return (
    <Router>
      <NavBar />
      <Switch>
        <Route exact path='/' component={Home} />
        <Route exact path='/login' component={SignIn} />
        <Route exact path='/register' component={SignUp} />
        <Route exact path='/about' component={AboutUs} />
        <Route exact path='/contact' component={ContactUs} />
        <ProtectedRoutes>
          <Route exact path='/market' component={MarketPlace} />
          <Route exact path='/profile' component={Profile} />
          <Route exact path='/profileDetails' component={ProfileDetails} />
        </ProtectedRoutes>
      </Switch>
      <Footer />
    </Router>
  );
};

export default Routes;
