import React, { useState, useEffect } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import Select from 'react-select';
import axios from 'axios';
// import { useDispatch} from 'react-redux';
import { faEdit, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useForm } from 'react-hook-form';
import notifications from '../../components/notifications';
import { usePlaceHolder } from '../../utils/usePlaceholder.js';
import { Loader } from '../../components/spinner';
// import { isLoggedIn } from '../../features/userAuthentication';

export const ContractorProfile = (props) => {
    const { register, handleSubmit, errors } = useForm();
    const [loading, setLoading] = React.useState(false);
    const [country, setCountry] = useState(null);
    const [contactLoading, setContactLoading] = useState(false);
    const [spinnerLoading, setSpinnerLoading] = useState(false);
    const [portfolioFile, setPortfolioFile] = useState(null);
    const [projectFile, setProjectFile] = useState(null);
    const [contractorProfileData, setContractorProfileData] = useState(null);
    const [companyModalShow, setCompanyModalShow] = useState(false);
    const [taxModalShow, setTaxModalShow] = useState(false);
    const [taxNumber, setTaxNumber] = useState(false);
    const [companyNumber, setCompanyNumber] = useState(false);
    const [employees, setEmployees] = useState(null);
    const [avgProjectValueExp, setAvgProjectValueExp] = useState(null);
    const [yearsOfExp, setYearsOfExp] = useState(null);
    const {
        placeholder,
        file,
        inputFile,
        onChange,
        onImageClick,
        resetPlaceholder
    } = usePlaceHolder();


    // getting agent's profile data if already exists
    useEffect(() => {
        setSpinnerLoading(true);
        axios
            .get('/profile?profileType=contractor')
            .then((res) => {
                setContractorProfileData(res.data.profile);
                setSpinnerLoading(false);
            })
            .catch((err) => {
                setSpinnerLoading(false);
                console.log(err.response.data);
            });
    }, []);

    const onSubmit = (data) => {
        setLoading(true);
        const {
            addressLine1,
            addressLine2,
            city,
            postCode,
        } = data;
        const imagesArray = [file];
        if (portfolioFile !== null) {
            imagesArray.push(portfolioFile);
        }
        if (projectFile !== null) {
            imagesArray.push(projectFile);
        }
        const formdata = new FormData();
        imagesArray.map(image => formdata.append('images', image));
        formdata.append('addressLine1', addressLine1);
        formdata.append('addressLine2', addressLine2);
        formdata.append('city', city);
        formdata.append('postCode', postCode);
        formdata.append('yearsOfExp', yearsOfExp);
        formdata.append('employees', employees);
        formdata.append('country', country);
        formdata.append('avgProjectValueExp', avgProjectValueExp);

        axios
            .patch('http://localhost:9091/profile/contractor', formdata)
            .then((res) => {
                setLoading(false);
                console.log(res);
                setContractorProfileData(res.data.doc);
                notifications.success('Success', 'Profile Updated Successfully');
            })
            .catch((err) => {
                setLoading(false);
                notifications.error('Profile Form Error', err.response.data.message);
            });
    };

    const updateContractorHandler = (data) => {
        setContactLoading(true);
        axios
            .patch("/profile/contractor/update", data)
            .then((res) => {
                setContactLoading(false);
                setContractorProfileData(res.data.doc);
                setCompanyModalShow(false);
                setTaxModalShow(false);
                console.log(res.data.doc);
            })
            .catch((err) => {
                setContactLoading(false);
                notifications.error("Contact Form Error", err.response.data.message);
            });
    }


    return (
        <>
            {spinnerLoading ? (
                <Loader />
            ) : (
                    <>
                        <Form onSubmit={handleSubmit(onSubmit)}>
                            {/* // picture */}
                            <div className='my-4 d-flex flex-column align-items-center'>
                                <Form.Label>Change Avatar</Form.Label>
                                <div
                                    className={`${
                                        placeholder ? '' : `bg-grey`
                                        } upload-circle shadow-sm rounded-circle`}
                                    type='button'
                                    onClick={onImageClick}
                                >
                                    <input
                                        type='file'
                                        id='file'
                                        accept='image/png/jpeg'
                                        ref={inputFile}
                                        style={{ display: 'none' }}
                                        onChange={onChange}
                                        name='file'
                                        required
                                    />
                                    {placeholder ? (
                                        <span class="avatar-container">
                                            <img
                                                src={placeholder}
                                                height='100%'
                                                width='100%'
                                                alt=''
                                                className='h-100 w-100 rounded-circle'
                                            />
                                        </span>
                                    ) : (
                                            <span class="avatar-container">
                                                <img
                                                    height='100%'
                                                    width='100%'
                                                    src={
                                                        contractorProfileData !== null
                                                            ? contractorProfileData.imageUrl
                                                            : 'https://graph.facebook.com/100008343750912/picture?width=400&height=400'
                                                    }
                                                    alt=''
                                                />
                                            </span>
                                        )}
                                </div>
                            </div>
                            {/* section for contractor profile */}
                            <Row>
                                <Col md={12}>
                                    <Form.Group>
                                        <Form.Label>Country</Form.Label>
                                        <Select
                                            options={props.countriesOptions}
                                            placeholder={contractorProfileData !== null
                                                ? contractorProfileData.country
                                                : 'Name Of Country'}
                                            onChange={(e) => setCountry(e.label)}
                                        />
                                    </Form.Group>
                                </Col>
                                {/* <Col className='align-self-end'>
                  <Button block variant='dark' className='mb-3'>
                    Save
                  </Button>
                </Col> */}
                                <Col md={12}>
                                    <Form.Group>
                                        <Form.Label>Address Line1</Form.Label>
                                        <Form.Control
                                            type='text'
                                            placeholder={
                                                contractorProfileData !== null
                                                    ? contractorProfileData.addressLine1
                                                    : 'addressLine1'
                                            }
                                            name='addressLine1'
                                            ref={register({ required: true })}
                                        />
                                        {errors.addressLine1 && (
                                            <small className='font-weight-bold text-danger'>
                                                This field is required
                    </small>
                                        )}
                                    </Form.Group>
                                </Col>
                                <Col md={12}>
                                    <Form.Group>
                                        <Form.Label>Address Line2</Form.Label>
                                        <Form.Control
                                            type='text'
                                            placeholder={
                                                contractorProfileData !== null
                                                    ? contractorProfileData.addressLine2
                                                    : 'Address Line2'
                                            }
                                            name='addressLine2'
                                            ref={register({ required: true })}
                                        />
                                        {errors.addressLine2 && (
                                            <small className='font-weight-bold text-danger'>
                                                This field is required
                    </small>
                                        )}
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group>
                                        <Form.Label>City</Form.Label>
                                        <Form.Control
                                            type='text'
                                            placeholder={
                                                contractorProfileData !== null ? contractorProfileData.city : 'city'
                                            }
                                            name='city'
                                            ref={register({ required: true })}
                                        />
                                        {errors.city && (
                                            <small className='font-weight-bold text-danger'>
                                                This field is required
                    </small>
                                        )}
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group>
                                        <Form.Label>PostCode</Form.Label>
                                        <Form.Control
                                            type='number'
                                            placeholder={
                                                contractorProfileData !== null
                                                    ? contractorProfileData.postCode
                                                    : 'postCode'
                                            }
                                            name='postCode'
                                            ref={register({ required: true })}
                                        />
                                        {errors.postCode && (
                                            <small className='font-weight-bold text-danger'>
                                                This field is required
                    </small>
                                        )}
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group className='small mt-1' controlId='formBasicPassword'>
                                        <Form.Label>
                                            Employees:
                    </Form.Label>
                                        <Select
                                            options={props.employeesList}
                                            placeholder={contractorProfileData !== null
                                                ? contractorProfileData.employees
                                                : 'Employees'}
                                            onChange={(e) => setEmployees(e.value)}
                                        />
                                    </Form.Group>
                                </Col>

                                <Col md={6}>
                                    <Form.Group className='small mt-1' controlId='formBasicPassword'>
                                        <Form.Label>
                                            Average project value experience:
                    </Form.Label>
                                        <Select
                                            options={props.averageValuesList}
                                            placeholder={contractorProfileData !== null
                                                ? contractorProfileData.avgProjectValueExp
                                                : 'Average Project Value Experience'}
                                            onChange={(e) => setAvgProjectValueExp(e.value)}
                                        />
                                    </Form.Group>
                                </Col>

                                <Col md={6}>
                                    <Form.Group className='small mt-1' controlId='formBasicPassword'>
                                        <Form.Label>
                                            Years of experience:
                    </Form.Label>
                                        <Select
                                            options={props.yearsOfExpList}
                                            placeholder={contractorProfileData !== null
                                                ? contractorProfileData.yearsOfExp
                                                : 'Years Of Experience'}
                                            onChange={(e) => setYearsOfExp(e.value)}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                </Col>
                                <Col md={6}>
                                    <Form.Group className='small mt-1' controlId='formBasicPortfolio'>
                                        <Form.Label>
                                            Portfolio: <small>(please submit documents, pdf)</small>
                                        </Form.Label>
                                        <Form.File
                                            id="exampleFormControlFile1"
                                            label='Example file input'
                                            required
                                            onChange={(e) => setPortfolioFile(e.target.files[0])}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group className='small mt-1' controlId='formBasicRef'>
                                        <Form.Label>
                                            Project references: <small>(please submit documents, pdf)</small>
                                        </Form.Label>
                                        <Form.File
                                            id="exampleFormControlFile2"
                                            label="Example file input"
                                            required
                                            onChange={(e) => setProjectFile(e.target.files[0])}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6} className='mt-5'>
                                    <Form.Group>
                                        <Button
                                            variant='bt-box-1'
                                            block
                                            className='p-3 text-left rounded-lg'
                                        >
                                            <div className='small font-weight-bold'>Company Number</div>
                                            <div className='text-black-50'>{contractorProfileData !== null ? contractorProfileData.companyNumber : '            '}</div>
                                            <div className='bt-box-1__item '>
                                                <Button
                                                    variant='success'
                                                    className='p-0 rounded-circle text-white d-flex align-items-center justify-content-center mr-1'
                                                    style={{ height: 22, width: 22 }}
                                                    onClick={() => setCompanyModalShow(true)}
                                                >
                                                    <FontAwesomeIcon size='xs' icon={faEdit} />
                                                </Button>
                                            </div>
                                        </Button>
                                    </Form.Group>
                                </Col>
                                <Col md={6} className='mt-5'>
                                    <Form.Group>
                                        <Button
                                            variant='bt-box-1'
                                            block
                                            className='p-3 text-left rounded-lg'
                                        >
                                            <div className='small font-weight-bold'>Tax Number</div>
                                            <div className='text-black-50'>{contractorProfileData !== null ? contractorProfileData.taxNumber : '            '}</div>
                                            <div className='bt-box-1__item '>
                                                <Button
                                                    variant='success'
                                                    className='p-0 rounded-circle text-white d-flex align-items-center justify-content-center mr-1'
                                                    style={{ height: 22, width: 22 }}
                                                    onClick={() => setTaxModalShow(true)}
                                                >
                                                    <FontAwesomeIcon size='xs' icon={faEdit} />
                                                </Button>

                                                {/* <Button
                                        variant='danger'
                                        className='p-0 rounded-circle text-white d-flex align-items-center justify-content-center'
                                        style={{ height: 22, width: 22 }}
                                    >
                                        <FontAwesomeIcon size='xs' icon={faTimes} />
                                    </Button> */}
                                            </div>
                                        </Button>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <div className='col col-md-6 mt-4 mx-auto px-0'>
                                <Button block variant='dark' type='submit'
                                    disabled={loading}
                                    className={`${loading === true ? 'button-disabled' : null}`}
                                >
                                    {loading === true ? (
                                        <div
                                            class='spinner-border spinner-border-sm text-light mr-2 mb-1'
                                            role='status'
                                        >
                                            <span class='sr-only'>Loading...</span>
                                        </div>
                                    ) : null}
                                    Submit
                </Button>
                            </div>
                        </Form>
                        <Modal size='sm' centered show={taxModalShow} onHide={() => setTaxModalShow(false)}>
                            <Modal.Body className='p-4'>
                                <h6 className='mb-3 font-weight-bold'>Update Tax Number</h6>
                                <Form>
                                    <Form.Group controlId='formBasicPhone'>
                                        <Form.Control
                                            className='input-group-text text-left'
                                            type='number'
                                            onChange={(e) => setTaxNumber(e.target.value)}
                                            placeholder='Enter Tax Number'
                                            required
                                        />
                                    </Form.Group>
                                    <div className='mt-4'>
                                        <Button
                                            variant='dark'
                                            block
                                            type='submit'
                                            className='text-capitalize'
                                            onClick={() => updateContractorHandler({ taxNumber })}
                                            disabled={contactLoading}>
                                            Save
                                    </Button>
                                    </div>
                                </Form>
                            </Modal.Body>
                        </Modal>
                        <Modal size='sm' centered show={companyModalShow} onHide={() => setCompanyModalShow(false)}>
                            <Modal.Body className='p-4'>
                                <h6 className='mb-3 font-weight-bold'>Update Company Number</h6>
                                <Form>
                                    <Form.Group controlId='formBasicEmail'>
                                        <Form.Control
                                            className='input-group-text text-left'
                                            type='number'
                                            placeholder='Enter company number'
                                            onChange={(e) => setCompanyNumber(e.target.value)}
                                            required
                                        />
                                    </Form.Group>
                                    <div className='mt-4'>
                                        <Button
                                            variant='dark'
                                            block
                                            type='submit'
                                            className='text-capitalize'
                                            onClick={() => updateContractorHandler({ companyNumber })}
                                            disabled={contactLoading}>
                                            <small>Save</small>
                                        </Button>
                                    </div>
                                </Form>
                            </Modal.Body>
                        </Modal>
                    </>
                )}
        </>
    );
};
