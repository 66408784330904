import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';

import { useHistory } from 'react-router-dom';
import { isLoggedIn } from '../features/userAuthentication';

export const useRedirect = () => {
  const dispatch = useDispatch();
  const { isAuthenticated } = useSelector((state) => state.user);
  const history = useHistory();

  useEffect(() => {
    if (isAuthenticated) {
      history.push('/profile');
    } else {
      dispatch(isLoggedIn());
    }
  }, [isAuthenticated, dispatch, history]);
};
