import React, { useState, useEffect } from 'react';
import {
  Button,
  Col,
  Container,
  Form,
  FormControl,
  Row,
} from 'react-bootstrap';
import axios from 'axios';
import Select from 'react-select';
import { useForm } from 'react-hook-form';
import notifications from '../components/notifications';
import { Loader } from '../components/spinner';
import { ProjectItem } from '../components/projectItem';

// images
import imageOne from '../assets/MarketPlace/image-1.jpg';
import moment from 'moment';

const options = [
  { value: 'avgProjectValueExp', label: 'avgProjectValueExp' },
  { value: 'yearsOfExp', label: 'yearsOfExp' },
  { value: 'acceptanceCriteria', label: 'acceptanceCriteria' },
];

const averageValuesOptions = [
  { value: '$0 - $100,000', label: '$0 - $100,000' },
  { value: '$100,000 - 250,000', label: '$100,000 - 250,000' },
  { value: '$250,000 - $500,000', label: '$250,000 - $500,000' },
  { value: '$500,000 - $1,000,000', label: '$500,000 - $1,000,000' },
  { value: '$1,000,000 - $5,000,000,', label: '$1,000,000 - $5,000,000,' },
  { value: '$5,000,000 - $10,000,000', label: '$5,000,000 - $10,000,000' },
  { value: '$10,000,000 - $25,000,000', label: '$10,000,000 - $25,000,000' },
  { value: '$25,000,000 - $50,000,000', label: '$25,000,000 - $50,000,000' },
  { value: '$50,000,000 - $100,000,000', label: '$50,000,000 - $100,000,000' },
  {
    value: '$100,000,000 - $250,000,000',
    label: '$100,000,000 - $250,000,000',
  },
  {
    value: '$250,000,000 - $500,000,000',
    label: '$250,000,000 - $500,000,000',
  },
  {
    value: '$500,000,000 - $1,000,000,000',
    label: '$500,000,000 - $1,000,000,000',
  },
  { value: '$1,000,000,000 and ABOVE', label: '$1,000,000,000 and ABOVE' },
];

const acceptanceCriteriaOptions = [
  { value: 'Finance/funding', label: 'Finance/funding' },
  { value: 'Design', label: 'Design' },
  { value: 'Contracting', label: 'Contracting' },
  { value: 'Feasibility', label: 'Feasibility' },
  {
    value: 'Delivering equipment/labour',
    label: 'Delivering equipment/labour',
  },
  { value: 'Media and Marketing', label: 'Media and Marketing' },
  {
    value: 'Skills and Technical training',
    label: 'Skills and Technical training',
  },
];

const yearsOfExpOptions = [
  { value: '1-10', label: '1-10' },
  { value: '10-20', label: '10-20' },
  { value: '20-30', label: '20-30' },
  { value: '30-50', label: '30-50' },
];

export const MarketPlace = () => {
  const { handleSubmit, register, errors } = useForm();
  const [loading, setLoading] = useState(false);
  const [spinnerLoading, setSpinnerLoading] = useState(false);
  const [search, setSearch] = useState(null);
  const [filter, setFilter] = useState(null);
  const [agentProjects, setAgentProjects] = useState([]);
  const [subFilter, setSubFilter] = useState([]);
  const [subFilterValue, setSubFilterValue] = useState(null);

  useEffect(() => {
    setSpinnerLoading(true);
    axios
      .get('/project/agent')
      .then((res) => {
        setAgentProjects(res.data.projects);
        setSpinnerLoading(false);
      })
      .catch((err) => {
        setSpinnerLoading(false);
      });
  }, []);

  const onSubmit = (data) => {
    setLoading(true);
    setSpinnerLoading(true);
    const newData = { filter, subFilterValue };
    if (data.search === '') {
      newData.search = null;
    } else {
      newData.search = data.search;
    }
    if (
      newData.search === null &&
      newData.filter === null &&
      newData.filter === null
    ) {
      setLoading(false);
    } else {
      axios
        .post('/project/agent/search', newData)
        .then((res) => {
          setLoading(false);
          setSpinnerLoading(false);
          setAgentProjects(res.data.projects);
        })
        .catch((err) => {
          setLoading(false);
          setSpinnerLoading(false);
          notifications.error('Search Form Error', err.response.data.message);
        });
    }
  };

  const changeFilterHandler = (e) => {
    setSubFilter([]);
    setFilter(e.value);
    if (e.value == 'avgProjectValueExp') {
      setSubFilter(averageValuesOptions);
    }
    if (e.value == 'yearsOfExp') {
      setSubFilter(yearsOfExpOptions);
    }
    if (e.value == 'acceptanceCriteria') {
      setSubFilter(acceptanceCriteriaOptions);
    }
  };

  return (
    <main className='bg-light'>
      <section className='pb-5'>
        <div
          className='d-flex align-items-center justify-content-center text-white text-center'
          style={{
            paddingTop: 70,
            height: '85vh',
            boxShadow: 'inset 0 0 0 1000px rgba(40,40,50,.8)',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundImage: `url(${imageOne})`,
          }}
        >
          <h1>Infrastructure Marketplace</h1>
        </div>
        <Container>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row className='justify-content-center rounded-lg bg-white shadow p-4 mt-n5'>
              <Col md={3} className='mb-3 mb-md-0'>
                <FormControl
                  type='text'
                  placeholder='Search'
                  name='search'
                  ref={register({ required: false })}
                />
              </Col>
              <Col md={3} className='mb-3 mb-md-0'>
                <Select
                  options={options}
                  placeholder='Filters'
                  onChange={changeFilterHandler}
                />
              </Col>
              {filter === null ? null : (
                <Col md={3} className='mb-3 mb-md-0'>
                  <Select
                    options={subFilter}
                    isDisabled={filter === null}
                    onChange={(e) => setSubFilterValue(e.value)}
                  />
                </Col>
              )}
              <Col md={3}>
                <Button
                  variant='outline-dark'
                  block
                  type='submit'
                  className={`${loading === true ? 'button-disabled' : null}`}
                >
                  {loading === true ? (
                    <div
                      class='spinner-border spinner-border-sm text-light mr-2 mb-1'
                      role='status'
                    >
                      <span class='sr-only'>Loading...</span>
                    </div>
                  ) : null}
                  Find
                </Button>
              </Col>
            </Row>
          </Form>
        </Container>
      </section>
      {spinnerLoading ? (
        <div className='p-4 text-center'>
          <Loader />
          Fetching Projects...
        </div>
      ) : (
          <>
            <section className='pb-5'>
              {agentProjects.length > 0 ? (
                <>
                  <h2 className='text-center mb-5'>Results </h2>
                  {agentProjects.map((project) => (
                    <ProjectItem project={project} />
                  ))}
                </>
              ) : (
                  <h2 className='text-center mb-5'> No Results...</h2>
                )}
            </section>
          </>
        )}
    </main>
  );
};
