import React from 'react';
import { Col, Container, Form, Row, Button } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import notifications from '../../components/notifications';

// Images
import imageOne from '../../assets/LogForm/image-1.svg';
import { useDispatch } from 'react-redux';
import { loginUser } from '../../features/userAuthentication';
import { useRedirect } from '../../hooks/useRedirect';

export const SignIn = () => {
  const { register, handleSubmit, errors } = useForm();
  const [loading, setLoading] = React.useState(false);

  useRedirect();

  const dispatch = useDispatch();
  const history = useHistory();

  const onSubmit = (data) => {
    setLoading(true);
    axios
      .post('/signin', data)
      .then((res) => {
        setLoading(false);
        dispatch(loginUser(res.data.token));
        history.push('/profile');
      })
      .catch((err) => {
        setLoading(false);
        notifications.error('Error', err.response.data.message);
      });
  };

  return (
    <section className='vh-100 vh-100-none-on-small d-flex align-items-center'>
      <Container>
        <Row className='justify-content-between align-items-center'>
          <Col md={5}>
            <div className='mb-3 small d-flex align-items-baseline'>
              <span className='text-muted font-weight-bold'>
                Not a member yet?
              </span>
              <Link to='/register' className='ml-2 text-info'>
                Join now
              </Link>
            </div>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Form.Group className='small' controlId='formBasicEmail'>
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  type='email'
                  name='email'
                  placeholder='Enter Email'
                  ref={register({ required: true })}
                />
                {errors.email && (
                  <small className='font-weight-bold text-danger'>
                    This field is required
                  </small>
                )}
                <Form.Text className='text-muted'>
                  We'll never share your email with anyone else.
                </Form.Text>
              </Form.Group>

              <Form.Group className='small' controlId='formBasicPassword'>
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type='password'
                  placeholder='Password'
                  name='password'
                  ref={register({ required: true })}
                />
                {errors.password && (
                  <small className='font-weight-bold text-danger'>
                    This field is required
                  </small>
                )}
              </Form.Group>
              {/* <Form.Group controlId='formBasicCheckbox'>
                <Form.Check type='checkbox' label='Check me out' />
              </Form.Group> */}
              <div className='mt-4'>
                <Button
                  block
                  variant='info'
                  type='submit'
                  disabled={loading}
                  className={`${loading === true ? 'button-disabled' : null}`}
                >
                  {loading === true ? (
                    <div
                      class='spinner-border spinner-border-sm text-light mr-2 mb-1'
                      role='status'
                    >
                      <span class='sr-only'>Loading...</span>
                    </div>
                  ) : null}
                  Submit
                </Button>
              </div>
            </Form>
          </Col>
          <Col md={6} className='order-first order-md-last mb-3 mb-md-0'>
            <img height='100%' width='100%' src={imageOne} alt='pic' />
          </Col>
        </Row>
      </Container>
    </section>
  );
};
