import React, { useRef, useState } from 'react';
import { Col, Container, Form, Row, Button } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import Select from 'react-select';

import axios from 'axios';
import { useForm } from 'react-hook-form';
import notifications from '../../components/notifications';

// Images
import imageOne from '../../assets/LogForm/image-2.png';
import { useDispatch } from 'react-redux';
import { loginUser } from '../../features/userAuthentication';
import { useRedirect } from '../../hooks/useRedirect';

const options = [
  {
    value: 'agent',
    label: 'Government Agency, Private Sector Organisation, Agents',
  },
  {
    value: 'contractor',
    label:
      'Contracting, Investing, Civil Engineering, Architects, Surveying, Media & Marketing',
  },
];

export const SignUp = () => {
  useRedirect();
  const { register, handleSubmit, errors, watch } = useForm();
  const password = useRef({});
  password.current = watch('password', '');
  const [loading, setLoading] = useState(false);
  const [AOI, setAOI] = useState(null);

  const history = useHistory();

  const dispatch = useDispatch();

  const onSubmit = (data) => {
    setLoading(true);
    const { email, password } = data;
    const newData = {
      email,
      password,
      aoi: AOI,
    };
    axios
      .post('/signup', newData)
      .then((res) => {
        setLoading(false);
        dispatch(loginUser(res.data.token));
        history.push('/profile');
      })
      .catch((err) => {
        setLoading(false);
        notifications.error('Signup Form Error', err.response.data.message);
      });
  };

  return (
    <section className='vh-100 vh-100-none-on-small d-flex align-items-center'>
      <Container>
        <Row className='justify-content-between align-items-center'>
          <Col lg={12} xl={6}>
            <div className='mb-3 small d-flex align-items-baseline'>
              <span className='text-muted font-weight-bold'>
                Already a member?
              </span>
              <Link to='/login' className='ml-2 text-info'>
                Sign In
              </Link>
            </div>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Row>
                <Col md={12}>
                  <Form.Group className='small' controlId='formBasicEmail'>
                    <Form.Label>Email address</Form.Label>
                    <Form.Control
                      type='email'
                      name='email'
                      placeholder='Enter Email'
                      ref={register({ required: true })}
                    />
                    {errors.email && (
                      <small className='font-weight-bold text-danger'>
                        This field is required
                      </small>
                    )}
                    <Form.Text className='text-muted'>
                      We'll never share your email with anyone else.
                    </Form.Text>
                  </Form.Group>
                </Col>
                <Col md={12}>
                  <Form.Group className='small' controlId='formBasicPassword'>
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                      type='password'
                      placeholder='Password'
                      name='password'
                      ref={register({
                        required: 'You must specify a password',
                        minLength: {
                          value: 8,
                          message: 'Password must have at least 8 characters',
                        },
                        pattern: {
                          value: /^(?=.*\d)(?=.*[A-Z]).{8,24}$/,
                          message:
                            'Password must be 8 to 24 characters long with at least one uppercase and one number character',
                        },
                      })}
                    />
                    {errors.password && (
                      <small className='font-weight-bold text-danger'>
                        {errors.password.message}
                      </small>
                    )}
                  </Form.Group>
                </Col>
                <Col md={12}>
                  <Form.Group controlId='formBasicPassword2'>
                    <Form.Label>Confirm your password</Form.Label>
                    <Form.Control
                      type='password'
                      placeholder='Password'
                      name='confirmPassword'
                      ref={register({
                        validate: (value) =>
                          value === password.current ||
                          'The passwords do not match',
                      })}
                    />
                    {/* {errors.password_repeat && <p>{errors.password_repeat.message}</p>} */}
                    {errors.confirmPassword && (
                      <small className='font-weight-bold text-danger'>
                        {errors.confirmPassword.message}
                      </small>
                    )}
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                {/* <Col md={6}>
                  <Form.Group>
                    <Form.Label>Select your requirements from below</Form.Label>
                    <Select options={options} placeholder='Requirements' />
                  </Form.Group>
                </Col> */}
                <Col md={12}>
                  <Form.Group className='small' controlId='formBasicPassword'>
                    <Form.Label>
                      Select from below your area of operation
                    </Form.Label>
                    <Select
                      options={options}
                      placeholder='Area of operation'
                      onChange={(e) => setAOI(e)}
                    />
                  </Form.Group>
                </Col>
              </Row>

              {/* <Row>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>Tell us your project finance needs</Form.Label>
                    <Form.Control
                      type='text'
                      placeholder='project finance needs'
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>
                      What is the size of your organisation
                    </Form.Label>
                    <Form.Control
                      type='text'
                      placeholder='size of your organisation'
                    />
                  </Form.Group>
                </Col>
              </Row> */}

              <div className='mt-4'>
                <Button
                  block
                  variant='info'
                  type='submit'
                  disabled={loading || AOI == null}
                  className={`${loading === true ? 'button-disabled' : null}`}
                >
                  {loading === true ? (
                    <div
                      class='spinner-border spinner-border-sm text-light mr-2 mb-1'
                      role='status'
                    >
                      <span class='sr-only'>Loading...</span>
                    </div>
                  ) : null}
                  Submit
                </Button>
              </div>
            </Form>
          </Col>
          <Col
            md={6}
            lg={5}
            className='order-first order-xl-last mb-3 mb-xl-0 mx-auto mx-xl-0'
          >
            <img height='100%' width='100%' src={imageOne} alt='pic' />
          </Col>
        </Row>
      </Container>
    </section>
  );
};
