import React, { useState, useEffect } from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import { User } from '../features/userAuthentication';

import Logo from '../assets/Logo.png';
import { logout, isLoggedIn } from '../features/userAuthentication';

export const NavBar = (props) => {
  const [navbar, setNavbar] = useState(false);

  const dispatch = useDispatch();

  const { user } = useSelector(User);

  const { isAuthenticated } = useSelector((state) => state.user);

  useEffect(() => {
    if (!isAuthenticated) {
      dispatch(isLoggedIn());
    }
  }, [isAuthenticated, dispatch]);

  const changeBackground = () => {
    if (window.scrollY >= 80) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  window.addEventListener('scroll', changeBackground);

  return (
    <Navbar
      bg='transparent'
      variant='dark'
      expand='md'
      className={navbar ? `fixed-top w-100 active` : 'fixed-top w-100'}
    >
      <Navbar.Brand as={Link} to='/' className='d-flex align-items-center'>
        <img
          alt=''
          src={Logo}
          // width='50'
          height='50'
          className='d-inline-block align-top mr-2'
          style={{ filter: 'invert(1)' }}
        />
      </Navbar.Brand>

      <div className='ml-auto'>
        <Navbar.Toggle aria-controls='basic-navbar-nav' />
        <Navbar.Collapse id='basic-navbar-nav'>
          <Nav className='mr-auto text-center my-3'>
            {isAuthenticated ? (
              <>
                {user.aoi.value === 'contractor' ?
                  <NavLink className='mr-3 mb-3 mb-md-0' to='/market'>
                    Markets
                </NavLink> : null}
                <NavLink className='mr-3 mb-3 mb-md-0' to='/profile'>
                  Profile
                </NavLink>
              </>
            ) : null}
            <NavLink className='mr-3 mb-3 mb-md-0' to='/about'>
              About
            </NavLink>
            <NavLink className='mr-3 mb-3 mb-md-0' to='/contact'>
              Contact us
            </NavLink>
            {isAuthenticated ? (
              <>
                <Link
                  className='mr-3 mb-3 mb-md-0'
                  to=''
                  onClick={() => {
                    dispatch(logout());
                  }}
                >
                  Logout
                </Link>
              </>
            ) : (
                <>
                  <NavLink className='mr-3 mb-3 mb-md-0' to='/login'>
                    Login
                </NavLink>
                  <NavLink className='mr-3 mb-3 mb-md-0' to='/register'>
                    Register
                </NavLink>
                </>
              )}
          </Nav>
        </Navbar.Collapse>
      </div>
    </Navbar>
  );
};
