import React, { useState, useEffect } from 'react';
import {
    Button,
} from 'react-bootstrap';
import axios from 'axios';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useSelector } from 'react-redux';
import notifications from '../components/notifications';
import { User } from '../features/userAuthentication';


export const ReqModal = ({ setShowReqModal, showReqModal, project }) => {
    const [reqLoading, setReqLoading] = useState(false);
    const { user } = useSelector(User);


    const handleRequest = () => {
        const data = {
            userEmail: user.email,
            projectTitle: project.title,
            projectId: project._id,
            projectAcceptanceCriteria: project.acceptanceCriteria
        }
        setReqLoading(true);
        axios
            .post('/project/agent/request', data)
            .then((res) => {
                setReqLoading(false);
                setShowReqModal(false);
                notifications.success('Success', 'Requested Successfully');
            })
            .catch((err) => {
                setReqLoading(false);
                notifications.error('Request Form Error', err.response.data.message);
            });
    }


    return (
        <>

            <Modal isOpen={showReqModal} toggle={() => setShowReqModal(!showReqModal)}>
                <ModalHeader toggle={() => setShowReqModal(!showReqModal)}>Request Info</ModalHeader>
                <ModalBody>Are You Sure You Want To Request for information of project {project.title}</ModalBody>
                <ModalFooter>
                    <Button color='light' onClick={() => setShowReqModal(false)}>
                        Cancel
                   </Button>
                    <Button
                        variant='dark'
                        disabled={reqLoading}
                        onClick={handleRequest}
                        className={`${
                            reqLoading === true
                                ? ' text-capitalize button-disabled'
                                : 'text-capitalize'
                            }`}
                    >
                        {reqLoading === true ? (
                            <div
                                class='spinner-border spinner-border-sm text-light mr-2 mb-0'
                                role='status'
                            >
                                <span class='sr-only'>Loading...</span>
                            </div>
                        ) : null}
                        Request
                   </Button>
                </ModalFooter>
            </Modal>
        </>
    );
};
